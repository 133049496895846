<template>
    <div class="callout">
        <div style="display: flex; gap: 25px; flex-wrap: wrap;">
            <input style="height: 42px;width:250px" type="text" class="form-control" placeholder="Expediente" v-model="filtroExpediente">    
            <MultiSelect optionValue="id" scrollHeight="400px" showClear v-model="companiaseleccionada" :options="companias" filter
                optionLabel="nombre"  style="width:250px" placeholder="Compañías">
            </MultiSelect>
        
            <MultiSelect optionValue="id" scrollHeight="400px" showClear v-model="usuarioseleccionado" :options="usuarios" filter
                optionLabel="nombre"  style="width:250px" placeholder="Usuarios">
            </MultiSelect>
            <button class="btn btn-primary" @click="obtenerExpedientes(mievento)">Buscar</button>
        </div>
    </div>
    <tabla-datos :botones="true" :solicitudes="true" :columnas="columnas" :datos="expedientes" :filtros="false" :total="total" @page="onPage($event)"
     @quieroDatos="obtenerExpedientes($event); mievento = $event;"  :page="pageselec" :num="numdatos" :servicios="true" v-if="mostrar">
    </tabla-datos>
</template>   
<script>
import MultiSelect from 'primevue/multiselect';
   import { PwgsApi } from '../../../services/PwgsApi';
   import TablaDatos from '../../TablaDatos.vue';
   
   export default {
       components: {
        MultiSelect,
           "tabla-datos": TablaDatos,
       },
       data() {
           return {
            mievento:[],
                mostrar:false,
                pageselec:1,
                numDatos:15,
                companias:[],
                filtroExpediente: '',
                companiaseleccionada: '',
                usuarioseleccionado: '',               
            usuarios:[],
               total: '',
               expedientes: [],
               parametros: '',
               columnas: [
                   {
                       header: 'Tipo',
                       field: 'class',
                   },
                   { 
                       header: 'Expediente',
                       field: 'codigo_servicio',
                       sortable: false,
                       acciones: [
                           {
                               link: true,
                               tipo: 'default',
                               nombreId: 'id_servicio',
                               nombreIddos: 'codigo_servicio',
                               // Debe ser arrow function para que this sea este componente
                               accion: (id, codigo) => {
                                   console.log('Editar expediente ' + id);
                                   localStorage.setItem('nombreservicio' + id, codigo);
                                   localStorage.setItem('pestanyactivaservicio' + id, 'comunicaciones');

                                   this.$router.push({
                                       name: 'Servicio',
                                       params: {
                                           id: id,
                                           nombreservicio: codigo,
                                       }
                                   });
                               }
                           },
                       ]
                   },
                   {
                       header: 'Compañia',
                       field: 'nombre_compania',
                   },
                   {
                       header: 'Fecha/Hora',
                       field: 'fecha_hora',
                       sortable: true,
                   },
                   {
                       header: 'Mensaje',    
                       field: 'texto',
                   },
                   {
                       header: 'Estado',
                       field: 'estado_servicio',
                   },
                   {
                       header: 'Tramitador',
                       field: 'nombre_tramitador',
                       sortable: false,
                   },
                   {
                       data: null,
                       acciones: [
                           
                           {
                               nombre: 'Eliminar',
                               class: 'eliminar',
                               tipo: 'danger',
                               nombreId: 'id_aviso',
                               nombreIddos: 'codigo_servicio',

                               icono: 'far fa-trash-alt',
                               // Debe ser arrow function para que this sea este componente
                               accion: (id, codigo_servicio) => {
                                   if (confirm('Desea eliminar la solicitud ' + codigo_servicio)) {

                                       this.eliminarsolicitud(id);
                                   }
                               }
                           },
                       ]
                   }
               ]
           }
       },
       methods: {
        async cargarCompanias() {
            const api = new PwgsApi();
            const datos = await api.get('companias/simple?sortField=nombre&sortOrder=1');
            this.companias = datos.datos;
        },
        async cargarUsuarios() {
            const api = new PwgsApi();
            const datos = await api.get('usuarios/gestion,simple');
            this.usuarios = datos.datos;
        },
        onPage(evnt){
                localStorage.setItem("pageselecsol", evnt.page);
                localStorage.setItem("numdatssol", evnt.rows);
                console.log("evvvv", evnt);
            },
           async obtenerExpedientes(event) {
            if (this.companiaseleccionada) {
            event.ids_companias = this.companiaseleccionada;
            }
            if (this.usuarioseleccionado) {
            event.ids_usuarios = this.usuarioseleccionado;
            }
            if(this.filtroExpediente !=''){
                event.buscador = this.filtroExpediente;
            }else{
                delete event.buscador;
            }
               const api = new PwgsApi;
               const aux = await api.post('avisos/solicitudes', event);
               this.expedientes = aux.datos;
               this.total = aux.n_total_registros;
               this.$emit('evento_total_solicitudes', this.total); //Es llamado por ./Escritorio.vue
           },
           async eliminarsolicitud(id_aviso) {
               //put pwgsapi/index.php/avisos/revisar-avisos
               const api = new PwgsApi();
               let ids = [];
               ids.push(id_aviso);
               let subidadatos = { tipo: 'solicita', ids_avisos: ids, estado: 1, }
               await api.put('avisos/revisar-avisos', subidadatos);
               this.obtenerExpedientes();
           }
       },
       watch: {
        usuarioseleccionado(){
            this.pageselec = 1;
            this.mievento["first"]=0;
            if (!this.usuarioseleccionado) {
                delete this.mievento["ids_usuarios"];
            }
        },
        companiaseleccionada(){
            this.pageselec = 1;
            this.mievento["first"]=0;
            if (!this.companiaseleccionada) {
                delete this.mievento["ids_companias"];
            }
        },
    },
       activated() {
          // this.obtenerExpedientes();
       },
        mounted() {
            this.cargarCompanias();
            this.cargarUsuarios();
            this.pageselec = parseInt(localStorage.getItem('pageselecsol'));
            this.numdatos = parseInt(localStorage.getItem('numdatssol'));
            console.log('pageselec',this.pageselec);
            this.mostrar = true;
            //this.obtenerExpedientes();
       }
   }
   </script>
<style>
   .p-dialog {
       width: 90%;
   }
   .p-datatable-header{
   
       visibility:hidden;
   }
   
   </style>